<template>
  <div
    id="myGroup"
    :style="
      activeNodes.length > 0 || activeEdges.length > 0
        ? 'height: 100%;background: #fff;'
        : 'height: 100%;background: rgba(0, 0, 0, 0.60);'
    "
  >
    <div
      class="trapezoid"
      :style="
        activeNodes.length > 0 || activeEdges.length > 0
          ? 'cursor: pointer;border-right: 45px solid #fff;'
          : 'cursor: pointer;border-right: 45px solid rgba(0, 0, 0, 0.60);'
      "
      @click="showGroup"
    >
      <div
        class="own_group"
        :title="active_group"
        :style="
          activeNodes.length > 0 || activeEdges.length > 0
            ? 'color: var(--font-icon-bl-1, rgba(0, 0, 0, 0.90));'
            : 'color: var(--font-icon-wh-1, rgba(255, 255, 255, 0.90));'
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 80%;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          "
        >
          <template v-for="(item, index) in active_group" :key="index">
            <span>
              {{ item }}
            </span>
          </template>
        </div>
        <div
          style="
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            v-if="showGroupInfo"
            src="../../assets/Frame 21.png"
            width="16"
            height="16"
          />
          <img v-else src="../../assets/Frame 22.png" width="16" height="16" />
        </div>
      </div>
    </div>
    <div
      class="my_group group_div flex_y_center"
      :style="showGroupInfo ? '' : 'display:none;'"
    >
      <div class="group">
        <div
          v-for="(item, index) of group"
          :key="item.id"
          :class="item.id == active_id ? 'active' : 'no_active'"
          class="group_item"
          @click="swich_group(item.id, item.name, hasKey(item,'isSilentMode') ? item.isSilentMode: false, hasKey(item,'isShowMode') ? item.isShowMode : true)"
        >
          <div
            :style="
              item.id == active_id
                ? 'color: var(--brand-click, #0061FF);text-align: center;font-family: SimSun;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;'
                : 'color: var(--font-icon-gy-1-primary, rgba(0, 0, 0, 0.90));text-align: center;font-family: SimSun;font-size: 16px;font-style: normal;font-weight: 400;line-height: 24px;'
            "
          >
            {{ item.name }}
          </div>
          <div
            v-if="item.group_member.length != 0"
            class="flex_x"
            style="
              justify-content: center;
              color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
              font-family: SimSun;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            "
            @click.stop="zhankai(index, item.id)"
          >
            共
            <div style="color: var(--brand-normal, #3681fc); font-weight: 600">
              {{ item.group_member.length }}
            </div>
            人
            <div style="margin-left: 10px">
              {{
                zhankai_id[index].show == true
                  ? languageData.closeItem
                  : languageData.launch
              }}
            </div>
            <img
              style="width: 20px; height: 20px; margin-top: 2px"
              :src="
                zhankai_id[index].show == true
                  ? require('../../assets/group/up.png')
                  : require('../../assets/group/down.png')
              "
            />
          </div>
          <div
            v-if="zhankai_id[index].show == true"
            class="group_member_item flex_x"
          >
            <div
              v-for="it of item.group_member"
              :key="it.userid"
              class="flex_x group_member"
              :title="it.name"
              @click="make_tag(it)"
            >
              <div
                :class="
                  it.state == 1
                    ? 'avatar'
                    : it.state == 0
                    ? 'off_avatar'
                    : 'op_avatar'
                "
              >
                {{ it.name.at(0) }}
              </div>
              <div>{{ it.name.substring(0, 5) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="checkbox_flag" class="flex_y_center checkbox_div">
      <div
        style="
          cursor: pointer;
          color: #ff0000;
          position: absolute;
          top: 2px;
          right: 2px;
        "
        @click="checkbox_flag = false"
      >
        <el-icon size="16"><CircleClose /></el-icon>
      </div>
      <strong style="margin-bottom: 10px; font-size: 14px"
        >{{ languageData.tip46 }}{{ tag_data.username
        }}{{ languageData.tip47 }}</strong
      >
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          :label="languageData.tip48"
          :value="languageData.tip48"
          :title="languageData.title48"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip49"
          :value="languageData.tip49"
          :title="languageData.title49"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip50"
          :value="languageData.tip50"
          :title="languageData.title50"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip51"
          :value="languageData.tip51"
          :title="languageData.title51"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip52"
          :value="languageData.tip52"
          :title="languageData.title52"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip53"
          :value="languageData.tip53"
          :title="languageData.title53"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip54"
          :value="languageData.tip54"
          :title="languageData.title54"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip55"
          :value="languageData.tip55"
          :title="languageData.title55"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip56"
          :value="languageData.tip56"
          :title="languageData.title56"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip57"
          :value="languageData.tip57"
          :title="languageData.title57"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip58"
          :value="languageData.tip58"
          :title="languageData.title58"
        ></el-checkbox>
        <el-checkbox
          :label="languageData.tip59"
          :value="languageData.tip59"
          :title="languageData.title59"
        ></el-checkbox>
      </el-checkbox-group>
      <div
        style="
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 20px;
        "
      >
        <el-button size="small" type="primary" @click="tag_submit">
          {{ languageData.tip60 }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElLoading as Loading } from "element-plus";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
// import bg from "../../assets/bg.png";
export default {
  name: "MyGroup",
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    teach_id: { type: String, default: undefined },
    allGroup: { type: Array, default: undefined },
    theme: { type: String, default: undefined },
    activeNodes: { type: Array, default: undefined },
    activeEdges: { type: Array, default: undefined },
    isShowGroup: { type: Boolean, default: undefined },
  },
  emits: ["changeSelectionSelect", "getGroup", "changeIsShowGroup","getUserGroup","getData","changeRate","setIsSilentMode","setIsShowMode"],
  data() {
    return {
      checkbox_flag: false,
      checkList: [],
      groupInfo: [],
      group: [],
      active_id: "",
      zhankai_id: [],
      active_group: "",
      showGroupInfo: false,
      time: null,
      groupList: [],
      tag_data: [],
      languageData: {},
    };
  },
  watch: {
    "$store.state.group"(newVal) {
      this.group = newVal;
      console.log(this.group);
    },
    activeNodes(newVal) {
      if (newVal.length == 0) {
        if (this.showGroupInfo && this.activeEdges.length == 0) {
          $(".DiagramRBToolbarDiv").css("right", "225px");
          $(".user_group").css("right", "225px");
          $(".diagram-toolbar-main").css("width", "calc(100% - 300px)");
          $(".diagram-toolbar-main").css("right", "300px");
        } else if (this.showGroupInfo && this.activeEdges.length > 0) {
          $(".DiagramRBToolbarDiv").css("right", "725px");
          $(".user_group").css("right", "725px");
          $(".diagram-toolbar-main").css("width", "calc(100% - 800px)");
          $(".diagram-toolbar-main").css("right", "800px");
        } else if (
          this.showGroupInfo == false &&
          this.activeEdges.length == 0
        ) {
          $(".DiagramRBToolbarDiv").css("right", "40px");
          $(".user_group").css("right", "2vw");
          $(".diagram-toolbar-main").css("width", "calc(100%)");
          $(".diagram-toolbar-main").css("right", "");
        } else if (this.showGroupInfo == false && this.activeEdges.length > 0) {
          $(".DiagramRBToolbarDiv").css("right", "545px");
          $(".user_group").css("right", "545px");
          $(".diagram-toolbar-main").css("width", "calc(100% - 600px)");
          $(".diagram-toolbar-main").css("right", "600px");
        }
      } else {
        if (
          newVal[0].type != "icon-start" &&
          newVal[0].type != "icon-thinking" &&
          newVal[0].type != "icon-whiteboard" &&
          newVal[0].type != "icon-map" &&
          newVal[0].type != "icon-camera" &&
          newVal[0].type != "icon-ai-npc"
        ) {
          if (this.showGroupInfo && this.activeEdges.length == 0) {
            $(".DiagramRBToolbarDiv").css("right", "725px");
            $(".user_group").css("right", "725px");
            $(".diagram-toolbar-main").css("width", "calc(100% - 800px)");
            $(".diagram-toolbar-main").css("right", "800px");
          } else if (
            this.showGroupInfo == false &&
            this.activeEdges.length == 0
          ) {
            $(".DiagramRBToolbarDiv").css("right", "545px");
            $(".user_group").css("right", "545px");
            $(".diagram-toolbar-main").css("width", "calc(100% - 600px)");
            $(".diagram-toolbar-main").css("right", "600px");
          }
        }
      }
    },
    activeEdges(newVal) {
      if (newVal.length == 0) {
        if (this.showGroupInfo && this.activeNodes.length == 0) {
          $(".DiagramRBToolbarDiv").css("right", "225px");
          $(".user_group").css("right", "225px");
          $(".diagram-toolbar-main").css("width", "calc(100% - 300px)");
          $(".diagram-toolbar-main").css("right", "300px");
        } else if (this.showGroupInfo && this.activeNodes.length > 0) {
          $(".DiagramRBToolbarDiv").css("right", "725px");
          $(".user_group").css("right", "725px");
          $(".diagram-toolbar-main").css("width", "calc(100% - 800px)");
          $(".diagram-toolbar-main").css("right", "800px");
        } else if (
          this.showGroupInfo == false &&
          this.activeNodes.length == 0
        ) {
          $(".DiagramRBToolbarDiv").css("right", "40px");
          $(".user_group").css("right", "2vw");
          $(".diagram-toolbar-main").css("width", "calc(100%)");
          $(".diagram-toolbar-main").css("right", "");
        } else if (this.showGroupInfo == false && this.activeNodes.length > 0) {
          $(".DiagramRBToolbarDiv").css("right", "545px");
          $(".user_group").css("right", "545px");
          $(".diagram-toolbar-main").css("width", "calc(100% - 600px)");
          $(".diagram-toolbar-main").css("right", "600px");
        }
      } else {
        if (
          newVal[0].type != "icon-start" &&
          newVal[0].type != "icon-thinking" &&
          newVal[0].type != "icon-whiteboard" &&
          newVal[0].type != "icon-map" &&
          newVal[0].type != "icon-camera" &&
          newVal[0].type != "icon-ai-npc"
        ) {
          if (this.showGroupInfo && this.activeNodes.length == 0) {
            $(".DiagramRBToolbarDiv").css("right", "725px");
            $(".user_group").css("right", "725px");
            $(".diagram-toolbar-main").css("width", "calc(100% - 800px)");
            $(".diagram-toolbar-main").css("right", "800px");
          } else if (
            this.showGroupInfo == false &&
            this.activeNodes.length == 0
          ) {
            $(".DiagramRBToolbarDiv").css("right", "545px");
            $(".user_group").css("right", "545px");
            $(".diagram-toolbar-main").css("width", "calc(100% - 600px)");
            $(".diagram-toolbar-main").css("right", "600px");
          }
        }
      }
    },
    isShowGroup(newVal) {
      this.showGroupInfo = newVal;
      if (
        this.showGroupInfo &&
        (this.activeNodes.length > 0 || this.activeEdges.length > 0)
      ) {
        $(".DiagramRBToolbarDiv").css("right", "725px");
        $(".user_group").css("right", "725px");
        $(".diagram-toolbar-main").css("width", "calc(100% - 800px)");
        $(".diagram-toolbar-main").css("right", "800px");
      } else if (
        this.showGroupInfo == false &&
        (this.activeNodes.length > 0 || this.activeEdges.length > 0)
      ) {
        $(".DiagramRBToolbarDiv").css("right", "545px");
        $(".user_group").css("right", "545px");
        $(".diagram-toolbar-main").css("width", "calc(100% - 600px)");
        $(".diagram-toolbar-main").css("right", "600px");
      } else if (
        this.showGroupInfo &&
        this.activeNodes.length == 0 &&
        this.activeEdges.length == 0
      ) {
        $(".DiagramRBToolbarDiv").css("right", "225px");
        $(".user_group").css("right", "225px");
        $(".diagram-toolbar-main").css("width", "calc(100% - 300px)");
        $(".diagram-toolbar-main").css("right", "300px");
      } else {
        $(".DiagramRBToolbarDiv").css("right", "40px");
        $(".user_group").css("right", "2vw");
        $(".diagram-toolbar-main").css("width", "calc(100%)");
        $(".diagram-toolbar-main").css("right", "");
      }
    },
  },
  mounted() {
    let that = this;
    if (window.location.href.indexOf("cocorobo.cn") != -1) {
      this.languageData = this.zhHansLanguage;
    } else if (window.location.href.indexOf("cocorobo.hk") != -1) {
      this.languageData = this.zhHantLanguage;
    } else if (window.location.href.indexOf("cocorobo.com") != -1) {
      this.languageData = this.enLanguage;
    } else {
      this.languageData = this.zhHansLanguage;
    }
    that.group = that.allGroup;
    let userAllInfo = [];
    let groupList = [];
    let params =
      "mode=" +
      [
        that.group.length > 1
          ? that.group[0].id
          : window.location.href.split("?cid=")[1].split("&gid=")[0],
      ].join(",");
    that.$ajax
      .post("https://pbl.cocorobo.cn/api/pbl/select_CSCLDataCount", params)
      .then((res) => {
        userAllInfo = res.data[0];
        userAllInfo.forEach((item) => {
          item.value = item.opcount;
        });
        that.$store.commit("set_select_CSCLDataCount", userAllInfo);
        for (let i = 0; i < that.group.length; i++) {
          let params =
            window.location.href.indexOf("localhost") != -1 ||
            window.location.href.indexOf("192.168") != -1
              ? "mode=" +
                [
                  "getcscldata",
                  that.group[i].id,
                  "d7300d31-8cf6-38dc-e295-2c71622b4df1",
                  "1cffdc4d-595f-11ea-af4c-52f45240a001",
                ].join(",")
              : "mode=" +
                [
                  "getcscldata",
                  that.group[i].id,
                  window.parent.US.pageId,
                  window.parent.US.userInfo.userid,
                ].join(",");
          that.$ajax
            .post("https://poll.cocorobo.cn", params)
            .then((res) => {
              try {
                res.data[0][0].data = JSON.parse(res.data[0][0].data);
              } catch {
                console.log();
              }
              groupList.push(res.data[0][0]);
              let user = res.data[0][0].user;
              for (let k = 0; k < that.group[i].group_member.length; k++) {
                let userInfo = that.group[i].group_member[k];
                userInfo.state = 0;
                for (let m = 0; m < user.length; m++) {
                  if (that.group[i].group_member[k].userid == user[m].userId) {
                    userInfo.state = 1;
                    break;
                  }
                }
                for (let m = 0; m < userAllInfo.length; m++) {
                  if (
                    userAllInfo[m].userid ==
                      that.group[i].group_member[k].userid &&
                    userAllInfo[m].create_at &&
                    new Date().getTime() -
                      new Date(userAllInfo[m].create_at).getTime() <=
                      30000
                  ) {
                    userInfo.state = 2;
                    break;
                  }
                }
              }
            })
            .catch((err) => {
              console.error(err);
            });
        }
        that.$emit("getUserGroup", groupList);
      })
      .catch((err) => {
        console.error(err);
      });

    that.time = setInterval(async function () {
      that.groupInfo = [];
      await that.getGroup();
      await that.getGroupInfo();
    }, 30000);
    let zhankai_id = [];
    if (that.group.length != 0) {
      for (let i = 0; i < that.group.length; i++) {
        zhankai_id.push({
          id: that.group[i].id,
          show: false,
        });
      }
      that.zhankai_id = zhankai_id;
      that.active_id = that.$store.state.group_active_id;
      that.active_group = that.$store.state.group_title;
      that.$emit("getGroup", that.active_id);
    }
  },
  methods: {
    hasKey(obj, key) {
      return key in obj; // 使用 in 操作符
    },
    tag_submit() {
      if (this.checkList.length == 0) {
        this.$message("请选择标签");
      } else {
        let params =
          window.location.href.indexOf("localhost") != -1 ||
          window.location.href.indexOf("192.168") != -1
            ? "mode=" +
              [
                this.tag_data.userid,
                this.tag_data.id,
                encodeURIComponent(
                  encodeURIComponent(this.checkList.join("-"))
                ),
                "1cf9dc4b-d95f-11ea-af4c-52540005ab01",
              ].join(",")
            : "mode=" +
              [
                this.tag_data.userid,
                this.tag_data.id,
                encodeURIComponent(
                  encodeURIComponent(this.checkList.join("-"))
                ),
                window.parent.US.userInfo.userid,
              ].join(",");
        this.$ajax
          .post("https://pbl.cocorobo.cn/api/pbl/add_userlabel", params)
          .then((res) => {
            console.log(res);
            this.$message({
              message: this.language.tip44,
              type: "success",
            });
            this.checkbox_flag = false;
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    make_tag(item) {
      this.checkList = [];
      this.checkbox_flag = true;
      this.tag_data = item;
    },
    async getGroup() {
      let that = this;
      let cid = window.location.href.split("?cid=")[1].split("&gid=")[0];
      let gid = window.location.href.split("&gid=")[1];
      let params = {
        cid: cid,
        classid: 1,
      };
      await that.$ajax
        .get("https://pbl.cocorobo.cn/api/pbl/getCourseGroup2", {
          params: params,
        })
        .then((res) => {
          let groups = [];
          if (res.data[0].length != 0) {
            groups = res.data[0];
            if (groups[0].group != null) {
              that.groupInfo = JSON.parse(groups[0].group).group;
            }
            // that.groupInfo = JSON.parse(groups[0].group).group;
          }
          if (gid == "") {
            let userid = "";
            try {
              userid = top.US.userInfo.userid;
            } catch {
              userid = "1cf9dc4b-d95f-11ea-af4c-52540005ab01";
            }
            if (userid == res.data[0][0].userid || top?.US?.userInfo.role == 1) {
              that.groupInfo.unshift((JSON.parse(groups[0].group).publicGroup)[0]);
              that.groupInfo.unshift({
                id: cid,
                name: that.languageData.myGroup,
                group_member: [
                  {
                    username: res.data[0][0].username,
                    userid: res.data[0][0].userid,
                    name: res.data[0][0].name,
                    groupCid: res.data[0][0].courseId,
                  },
                ],
              });
            }
          } else {
            let newGroup = [];
            if(top?.US?.userInfo.type == 1) {
              for (let i = 0; i < that.groupInfo.length; i++) {
                newGroup.push(that.groupInfo[i]);
              }
            }
            else if(top?.US?.userInfo.type == 2) {
              if(JSON.parse(groups[0].group).isAllStudentRead && JSON.parse(groups[0].group).isAllStudentEdit) {
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id == gid) {
                    newGroup.push(that.groupInfo[i]);
                    break
                  }
                }
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id != gid) {
                    newGroup.push(that.groupInfo[i]);
                  }
                }
              }
              else if(JSON.parse(groups[0].group).isAllStudentRead && !JSON.parse(groups[0].group).isAllStudentEdit) {
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id == gid) {
                    that.groupInfo[i].isSilentMode = false
                    that.groupInfo[i].isShowMode = true
                    newGroup.push(that.groupInfo[i]);
                    break
                  }
                }
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id != gid) {
                    that.groupInfo[i].isSilentMode = true
                    that.groupInfo[i].isShowMode = false
                    newGroup.push(that.groupInfo[i]);
                  }
                }
              }
              else {
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id == gid) {
                    newGroup.push(that.groupInfo[i]);
                    break;
                  }
                }
              }
            }
            else {
              if(JSON.parse(groups[0].group).isAllStudentRead && JSON.parse(groups[0].group).isAllStudentEdit) {
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id == gid) {
                    newGroup.push(that.groupInfo[i]);
                    break
                  }
                }
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id != gid) {
                    newGroup.push(that.groupInfo[i]);
                  }
                }
              }
              else if(JSON.parse(groups[0].group).isAllStudentRead && !JSON.parse(groups[0].group).isAllStudentEdit) {
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id == gid) {
                    that.groupInfo[i].isSilentMode = false
                    that.groupInfo[i].isShowMode = true
                    newGroup.push(that.groupInfo[i]);
                    break
                  }
                }
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id != gid) {
                    that.groupInfo[i].isSilentMode = true
                    that.groupInfo[i].isShowMode = false
                    newGroup.push(that.groupInfo[i]);
                  }
                }
              }
              else {
                for (let i = 0; i < that.groupInfo.length; i++) {
                  if (that.groupInfo[i].id == gid) {
                    newGroup.push(that.groupInfo[i]);
                    break;
                  }
                }
              }
            }
            newGroup.push((JSON.parse(groups[0].group).publicGroup)[0]);
            that.groupInfo = newGroup;
          }
          that.groupInfo.forEach((it) => {
            it.group_member_desc = that.languageData.launch;
            it.creator = {
              username: res.data[0][0].username,
              userid: res.data[0][0].userid,
            };
            it.group_member_flag = false;
            if (!it.group_member) {
              it.group_member = [];
              res.data[1].forEach((item) => {
                if (item.groupCid == it.id) {
                  it.group_member.push(item);
                }
              });
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getGroupInfo() {
      let that = this;
      let userAllInfo = [];
      let groupList = [];
      let group = that.groupInfo;
      let params =
        "mode=" +
        [
          group.length > 1
            ? group[0].id
            : window.location.href.split("?cid=")[1].split("&gid=")[0],
        ].join(",");
      await that.$ajax
        .post("https://pbl.cocorobo.cn/api/pbl/select_CSCLDataCount", params)
        .then((res) => {
          userAllInfo = res.data[0];
          userAllInfo.forEach((item) => {
            item.value = item.opcount;
          });
          groupList = [];
          that.$store.commit("set_select_CSCLDataCount", userAllInfo);
        })
        .catch((err) => {
          console.error(err);
        });
      that.group = await that.getFinalGroup(group, groupList, userAllInfo);
      //console.log(JSON.stringify(that.group));
    },
    async getFinalGroup(group, groupList, userAllInfo) {
      let that = this;
      for (let i = 0; i < group.length; i++) {
        let params =
          window.location.href.indexOf("localhost") != -1 ||
          window.location.href.indexOf("192.168") != -1
            ? "mode=" +
              [
                "getcscldata",
                group[i].id,
                "d7300d31-8cf6-38dc-e295-2c71622b4df1",
                "1cffdc4d-595f-11ea-af4c-52f45240a001",
              ].join(",")
            : "mode=" +
              [
                "getcscldata",
                group[i].id,
                window.parent.US.pageId,
                window.parent.US.userInfo.userid,
              ].join(",");
        await that.$ajax
          .post("https://poll.cocorobo.cn", params)
          .then((res) => {
            try {
              res.data[0][0].data = JSON.parse(res.data[0][0].data);
            } catch {
              console.log();
            }
            groupList.push(res.data[0][0]);
            let user = res.data[0][0].user;
            if (group[i].group_member) {
              for (let k = 0; k < group[i].group_member.length; k++) {
                let userInfo = group[i].group_member[k];
                userInfo.state = 0;
                for (let m = 0; m < user.length; m++) {
                  if (group[i].group_member[k].userid == user[m].userId) {
                    userInfo.state = 1;
                    break;
                  }
                }
                for (let m = 0; m < userAllInfo.length; m++) {
                  if (
                    userAllInfo[m].userid == group[i].group_member[k].userid &&
                    userAllInfo[m].create_at &&
                    new Date().getTime() -
                      new Date(userAllInfo[m].create_at).getTime() <=
                      30000
                  ) {
                    userInfo.state = 2;
                    break;
                  }
                }
                group[i].group_member[k] = userInfo;
              }
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
      that.$emit("getUserGroup", groupList);
      return group;
    },
    showGroup() {
      this.$emit("changeIsShowGroup", !this.showGroupInfo);
      // this.showGroupInfo = !this.showGroupInfo;
    },
    swich_group(id, name, isSilentMode, isShowMode) {
      if (this.active_id != id) {
        console.log(isSilentMode, isShowMode)
        this.$emit("changeSelectionSelect", true);
        let old_id = this.active_id;
        this.active_id = id;
        this.$store.commit("set_group_active_id", id);
        this.active_group = name;
        let zhankai_id = this.zhankai_id;
        this.zhankai_id = [];
        for (let i = 0; i < zhankai_id.length; i++) {
          if (zhankai_id[i].id == id) {
            zhankai_id[i].show = true;
            break;
          }
        }
        this.zhankai_id = zhankai_id;
        localStorage.setItem("roomId", id);
        this.$emit("getGroup", this.active_id);
        this.$emit("setIsSilentMode", isSilentMode);
        this.$emit("setIsShowMode", isShowMode);
        let loadingInstance = Loading.service({ fullscreen: true });
        this.$_loginout(old_id, id, loadingInstance);
      }
    },
    $_loginout(old_id, id, loadingInstance) {
      let that = this;
      let params =
        window.location.href.indexOf("localhost") != -1 ||
        window.location.href.indexOf("192.168") != -1
          ? "mode=" +
            [
              "quitcscl",
              encodeURIComponent(
                encodeURIComponent(
                  JSON.stringify({
                    docId: old_id,
                    pageId: "d7300d31-8cf6-38dc-e295-2c71622b4df1",
                    userId: "1cf9dc4b-d95f-11ea-af4c-52540005ab01",
                    type: "cscl",
                  })
                )
              ),
            ].join(",")
          : "mode=" +
            [
              "quitcscl",
              encodeURIComponent(
                encodeURIComponent(
                  JSON.stringify({
                    docId: old_id,
                    pageId: top.US.pageId,
                    userId: top.US.userInfo.userid,
                    type: "cscl",
                  })
                )
              ),
            ].join(",");
      this.$ajax
        .post("https://poll.cocorobo.cn", params)
        .then((res) => {
          // console.log(res.data)
          if (res.data == "quitcscl") {
            this.getcscl(id, loadingInstance);
          }
        })
        .catch((err) => {
          console.error(err);
          that.$message({
            message: err,
            type: "error",
          });
        });
    },
    getcscl(id, loadingInstance) {
      let that = this;
      let params =
        window.location.href.indexOf("localhost") != -1 ||
        window.location.href.indexOf("192.168") != -1
          ? "mode=" +
            [
              "getcscl",
              id,
              "d7300d31-8cf6-38dc-e295-2c71622b4df1",
              "1cffdc4d-595f-11ea-af4c-52f45240a001",
            ].join(",")
          : "mode=" +
            [
              "getcscl",
              id,
              window.parent.US.pageId,
              window.parent.US.userInfo.userid,
            ].join(",");
      this.$ajax
        .post("https://poll.cocorobo.cn", params)
        .then((res) => {
          let data = {};
          localStorage.setItem("user", JSON.stringify(res.data[0][0].user));
          that.$store.commit("set_group_member_active", []);
          that.$store.commit("set_group_member_active", res.data[0][0].user);
          try {
            data = JSON.parse(res.data[0][0].data);
          } catch {
            data = res.data[0][0].data;
          }
          if (data.nodes == null) {
            // localStorage.setItem("data", JSON.stringify({ "nodes": [{ "id": "9f6d7459-d376-4c0c-944b-a6b140085903", "type": "icon-start", "x": 625, "y": 145, "properties": {}, "text": { "x": 405, "y": 180, "value": "起始" }, "zIndex": 1002 }], "edges": [], "backgroundUrl": "img/bg.ccee1c62.png" }))
            let new_id = uuidv4();
            data = {
              nodes: [
                {
                  id: new_id,
                  type: "icon-theme",
                  x: 355,
                  y: 350,
                  properties: {
                    isShowDelete: false,
                    id: new_id,
                    type: "icon-theme",
                    topic: that.theme != "" ? that.theme : "起始",
                  },
                  zIndex: 1002,
                },
              ],
              edges: [],
              backgroundUrl: "",
            };
            let mindinfo = {
              "us.cscl": [
                {
                  sendId:
                    window.location.href.indexOf("localhost") != -1 ||
                    window.location.href.indexOf("192.168") != -1
                      ? "1cf9dc4b-d95f-11ea-af4c-52540005ab01"
                      : window.parent.US.userInfo.userid, //发送人id
                  receiveId: id, //文件id
                  pageId:
                    window.location.href.indexOf("localhost") != -1 ||
                    window.location.href.indexOf("192.168") != -1
                      ? "d7300d31-8cf6-38dc-e295-2c71622b4df1"
                      : window.parent.US.pageId,
                  type: "update", //消息类型
                  messageInfo: data,
                },
              ],
            };
            let params =
              "mode=" +
              [
                "send",
                encodeURIComponent(
                  encodeURIComponent(JSON.stringify(mindinfo))
                ),
              ].join(",");
            this.$ajax
              .post("https://poll.cocorobo.cn", params)
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.error(err);
                loadingInstance.close();
                that.$message({
                  message: err,
                  type: "error",
                });
              });
            // data = {
            //   nodes: [
            //     {
            //       id: "ecb16218-68b0-47db-a0c2-d5329c75fcf5",
            //       type: "icon-theme",
            //       x: 355,
            //       y: 350,
            //       properties: {
            //         isShowDelete: false,
            //         id: "ecb16218-68b0-47db-a0c2-d5329c75fcf5",
            //         type: "icon-theme",
            //         topic: "起始",
            //       },
            //       zIndex: 1002,
            //     },
            //   ],
            //   edges: [],
            //   backgroundUrl: bg,
            // };
            // localStorage.setItem("data", JSON.stringify(data))
            // that.data = data
            that.$emit("getData", data);
            localStorage.setItem("background", data.backgroundUrl);
          } else {
            let nodes = data.nodes;
            let edges = data.edges;
            let newNodes = [];
            let newEdges = [];
            for (let i = 0; i < nodes.length; i++) {
              if (nodes[i] !== null && typeof nodes[i] === "object") {
                newNodes.push(nodes[i]);
              }
            }
            for (let i = 0; i < edges.length; i++) {
              if (edges[i] !== null && typeof edges[i] === "object") {
                newEdges.push(edges[i]);
              }
            }
            data.nodes = newNodes;
            data.edges = newEdges;
            that.$emit("getData", data);
            localStorage.setItem("background", data.backgroundUrl);
          }
          loadingInstance.close();
          localStorage.setItem("network", true);
          that.$emit("changeRate", true);
        })
        .catch((err) => {
          console.error(err);
          loadingInstance.close();
          that.$message({
            message: err,
            type: "error",
          });
        });
    },
    zhankai(index, itemid) {
      let zhankai_id = this.zhankai_id;
      this.zhankai_id = [];
      let show = zhankai_id[index].show;
      zhankai_id[index] = {
        id: itemid,
        show: !show,
      };
      this.zhankai_id = zhankai_id;
    },
  },
};
</script>

<style>
#myGroup .el-checkbox {
  height: 19.22px;
}
.checkbox_div .el-checkbox-group {
  display: flex;
  flex-direction: column;
}
</style>
<style scoped>
.my_group {
  background: none;
  border: none;
  padding: 0;
}

.group_div {
  position: relative;
  text-align: center;
  width: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.group_div .flex_y_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group {
  width: 144px;
  max-height: 100%;
}

.group_item {
  width: 85%;
  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #e7eefe 0%, #fafcff 100%);
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.active {
  border-radius: 8px;
  border: 1px solid var(--brand-normal, #3681fc);
  background: linear-gradient(180deg, #e7eefe 0%, #fafcff 100%);
}

.no_active {
  border: 1px solid #979797;
}

.group_member_item {
  position: relative;
  flex-wrap: wrap;
}
.checkbox_div {
  position: absolute;
  top: 36%;
  left: -200px;
  background: #fff;
  border-radius: 5px;
  padding: 10px 20px;
}
.group_member {
  margin-bottom: 5px;
  margin-right: 5px;
  color: var(--font-icon-gy-2-secondary, rgba(0, 0, 0, 0.6));
  text-align: center;
  /* body/small */
  font-family: SimSun;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.avatar {
  width: 16px;
  height: 16px;
  background: rgba(234, 161, 59, 1);
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  line-height: 16px;
  margin-top: 2px;
  margin-right: 3px;
}

.off_avatar {
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  line-height: 16px;
  margin-top: 2px;
  margin-right: 3px;
}

.op_avatar {
  width: 16px;
  height: 16px;
  background: rgba(90, 193, 170, 1);
  color: #fff;
  font-weight: bold;
  border-radius: 50%;
  line-height: 16px;
  margin-top: 2px;
  margin-right: 3px;
}

.trapezoid {
  height: auto;
  width: 0;
  border-right: 45px solid rgba(0, 0, 0, 0.6);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  position: absolute;
  left: -45px;
  top: 40%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.own_group {
  color: var(--font-icon-wh-1, rgba(255, 255, 255, 0.9));
  font-family: SimSun;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  width: 46px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
  /* flex-direction: column;
  align-items: center; */
}
</style>
